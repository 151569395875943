.PaymentSchedule .table {
  text-align: left;
}
.PaymentSchedule .tr,
.PaymentSchedule .thead {
  display: flex;
}

.PaymentSchedule .td {
  width: 110px;
  flex-grow: 0;
}
