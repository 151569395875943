.settlements-container {
  flex: 0 1 auto;
  border: 1px solid var(--border-color);
  animation: fade-in 500ms;
}

.settlements__table {
  width: 100%;
  min-width: 700px;
}
.settlements__table tbody {
  min-height: 20px;
}
.settlement__writeoff {
  text-align: right;
}
.settlement__writeoff .sort-cell__inner {
  justify-content: flex-end;
}
